import React, { useEffect, useState } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useHistory } from 'react-router-dom'
import {
  // breadCrumbReset,
  setBreadCrumbs,
  setSelectedBreadCrumbs,
} from 'app/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { toggleDrawer } from 'app/Redux/slices/user/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import userAvatar from '../../Assets/userAvatar.jpg'
import ChangePassword from '../Header/ChangePassword'
import Menu from '../Header/Menu'
import Logout from '../Logout'
import Image from 'mui-image'
import { useTeams } from './../Teams/useTeams'
import { getRole, isEmployee, isInternee, isStaff } from 'app/Utils/helper'
import {
  getConsumableItemName,
  getConsumableName,
} from './../../Redux/slices/Consumables/index'

import {
  getfixedAssetType,
  getfixedAssetEntityName,
  getfixedAsset,
} from 'app/Redux/slices/FixedAsset'
import { baseUrl } from 'app/Utils/Config'

const TopBar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [isLogout, setIsLogout] = useState(false)
  const loggedInEmployeeName = localStorage.getItem(`loggedInEmployeeName`)
  const loggedInEmployeeSystemRole = getRole()
  const loggedInEmployeeProfilePicture = localStorage.getItem(`profilePicture`)
  const { breadCrumbs } = useSelector(({ breadCrumbs }) => breadCrumbs)
  const drawerState = useSelector((state) => state.drawer)

  const [profileImageSrc, setProfileImageSrc] = useState(`${baseUrl}/users/public/download-document/${loggedInEmployeeProfilePicture}`)

  const { _id: accountId, name: accountName } = useSelector(
    ({ account }) => account?.currentAccount,
  )
  const { firstName, lastName } = useSelector(
    (state) => state.attendance.currentAttendance,
  )

  const consumableName = useSelector(getConsumableName)
  const consumableItemName = useSelector(getConsumableItemName)

  const fixedAssetTypeName = useSelector(getfixedAssetType)
  const fixedAssetEntityName = useSelector(getfixedAssetEntityName)
  const fixedAssetName = useSelector(getfixedAsset)

  const { getTeamName } = useTeams({})

  let {
    id,
    budgetId,
    paramsId,
    paramsName,
    userId,
    payrollId,
    fundsId,
    invoiceId,
    assessmentId,
    myAssessmentId,
    jobId,
    teamId,
    contractId,
    assetId,
    consumableId,
    consumableItemId,
    fixedAssetTypeId,
    entityId,
    fixedAssetId,
  } = useParams()

  const allBreadcrumbs = {
    '/dashboard': {
      title: `Dashboard`,
    },
    '/users': {
      title: `Users`,
    },
    '/users/add': {
      title: `Add`,
    },
    [`/users/${id}`]: {
      title: `User Detail`,
    },
    '/expenses': {
      title: `Expenses`,
    },
    '/quotations': {
      title: `Quotations`,
    },
    '/gallery': {
      title: `Gallery`,
    },
    '/QRCode': {
      title: `QR Code Generator`,
    },
    '/configuration': {
      title: `Configuration`,
    },
    '/accounts': {
      title: `Accounts`,
    },
    [`/accounts/${accountId}`]: {
      title: accountName,
    },
    [`/accounts/${accountId}/budget/${budgetId}`]: {
      title: `Expenses`,
    },
    '/attendance': {
      title: `Attendance`,
    },
    [`/attendance/attendanceIndividual/${paramsId}/${paramsName}`]: {
      title: `${firstName} ${lastName}`,
    },
    '/payroll': {
      title: `Payroll`,
    },
    [`/payroll/payrollSlip/${userId}/${payrollId}`]: {
      title: `Pay Slip`,
    },
    '/payroll/payrollAnalytics': {
      title: `Analytics`,
    },
    '/requests': {
      title: `Requests`,
    },
    '/consumables': {
      title: `Consumables`,
    },

    [`/consumables/${consumableId}`]: {
      title: consumableName,
    },
    [`/consumables/${consumableId}/${consumableItemId}`]: {
      title: consumableItemName,
    },
    '/consumable-history': {
      title: `Consumables History`,
    },
    '/fixed-assets/types': {
      title: `Fixed Assets`,
    },
    [`/fixed-assets/types/${fixedAssetTypeId}/entities`]: {
      title: fixedAssetTypeName,
    },
    [`/fixed-assets/types/${fixedAssetTypeId}/entities/${entityId}/items`]: {
      title: fixedAssetEntityName,
    },
    [`/fixed-assets/types/${fixedAssetTypeId}/entities/${entityId}/items/${fixedAssetId}`]:
      {
        title: fixedAssetName,
      },
    [`/fixed-assets/types/${fixedAssetTypeId}/entities/${entityId}/items/allotment`]:
      {
        title: `Allotment`,
      },
    '/leads': {
      title: `Leads`,
    },
    '/taxDetails': {
      title: `Tax Details`,
    },
    '/taxSlabs': {
      title: `Tax Slabs`,
    },
    '/applicants': {
      title: `All Applicants`,
    },
    '/applicants/add': {
      title: `Add Applicant`,
    },
    '/jobs': {
      title: `Jobs`,
    },
    [`/jobs/details/${jobId}`]: {
      title: `Job Detail`,
    },
    [`/jobs/update/${jobId}`]: {
      title: `Update Job`,
    },
    '/projects': {
      title: `Projects`,
    },
    '/teams': {
      title: `Teams`,
    },
    [`/teams/${teamId}`]: {
      title: getTeamName(teamId),
    },
    '/designations': {
      title: `Designations`,
    },
    '/departments': {
      title: `Departments`,
    },
    '/positions': {
      title: `Positions`,
    },
    '/holidays': {
      title: `Holidays`,
    },
    '/shifts': {
      title: `Shifts`,
    },
    '/contracts': {
      title: `Contracts`,
    },
    [`/contracts/${contractId}`]: {
      title: `Contract Detail`,
    },
    '/assets': {
      title: `Assets`,
    },
    [`/assets/${assetId}`]: {
      title: `Asset Detail`,
    },
    '/accounts/budgetReports': {
      title: `Reports`,
    },
    '/accounts/budgetReports/generateReports': {
      title: `Generate Reports`,
    },
    '/expenses/expenseReports': {
      title: `Generate Reports`,
    },
    '/funds': {
      title: `Funds`,
    },
    [`/funds/${fundsId}`]: {
      title: `Transactions`,
    },
    '/ExpenditureAnalytics': {
      title: `Dashboard`,
    },
    '/income': {
      title: `Invoices`,
    },

    '/income/addInvoice': {
      title: `Add Invoice`,
    },
    [`/income/${invoiceId}`]: {
      title: `Preview`,
    },
    [`/income/${invoiceId}/updateInvoice`]: {
      title: `Update`,
    },
    '/clients': {
      title: `Clients`,
    },
    '/business': {
      title: `Business`,
    },

    '/businessDeveloperApplicant': {
      title: `Business Applicants`,
    },

    '/assessments': {
      title: `Assessments`,
    },
    '/assessments/addbulk': {
      title: `Add Bulk`,
    },
    '/assessments/multipleAssessments': {
      title: `Multiple Assessments`,
    },
    [`/assessments/update/${assessmentId}`]: {
      title: `Update`,
    },
    [`/assessments/${myAssessmentId}`]: {
      title: `My Assessment`,
    },
    '/loanReport': {
      title: `Loan Reports`,
    },
  }

  useEffect(() => {
    // dispatch(breadCrumbReset([{ title: `Dashboard`, path: `/dashboard` }]))
    const navButton = history.location.pathname.split(`/`)
    const updatedBreadcrumbs = []
    for (let i = 1; i < navButton.length; i++) {
      const matchRoute = navButton.slice(0, i + 1).join(`/`)
      if (allBreadcrumbs[matchRoute]) {
        updatedBreadcrumbs.push({
          title: allBreadcrumbs[matchRoute].title,
          path: matchRoute,
        })
      }
    }
    dispatch(setBreadCrumbs(updatedBreadcrumbs))
  }, [
    history.location.pathname,
    id,
    accountId,
    budgetId,
    paramsId,
    paramsName,
    firstName,
    lastName,
    userId,
    fundsId,
    invoiceId,
    assessmentId,
    myAssessmentId,
  ])

  const handleProfileClick = () => {
    const id = localStorage.getItem(`loggedInUserId`)

    setAnchorEl(false)
    history.push(`/users/${id}`)
  }
  return (
    <>
      {changePasswordModal && (
        <ChangePassword
          open={changePasswordModal}
          onClose={() => setChangePasswordModal(!changePasswordModal)}
        />
      )}
      {isLogout && (
        <Logout open={isLogout} onClose={() => setIsLogout(!isLogout)} />
      )}

      {anchorEl && (
        <Menu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          OpenProfile={handleProfileClick}
          OpenChangePassword={() => {
            setAnchorEl(null)
            setChangePasswordModal(!changePasswordModal)
          }}
          OpenLogout={() => {
            setAnchorEl(null)
            setIsLogout(!isLogout)
          }}
        />
      )}
      <Box
        sx={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          paddingY: `40px`,
          paddingX: { xs: `10px`, sm: `20px` },
          backgroundColor: `inherit`,
          height: `50px`,
        }}
      >
        <Box sx={{ display: `flex`, alignItems: `center`, gap: `10px` }}>
          {!drawerState.openDrawer && (
            <MenuIcon
              sx={{ '&:hover': { cursor: `pointer` }, color: `#6039BB` }}
              onClick={() =>
                dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
              }
            />
          )}

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="large" />}
            aria-label="breadcrumb"
          >
            {breadCrumbs.map((data, index) => (
              <button
                key={index}
                onClick={() => {
                  if (
                    (data?.path === `/attendance` &&
                      (isEmployee() || isStaff())) ||
                    isInternee() ||
                    (data?.path === '/users' && isStaff())
                  ) {
                    return
                  } else {
                    history.push(data?.path)
                    dispatch(setSelectedBreadCrumbs(index))
                  }
                }}
              >
                <Typography
                  sx={{
                    display: `flex`,
                    color:
                      index === breadCrumbs.length - 1
                        ? `rgba(40, 32, 61, 1)`
                        : `rgba(40, 32, 61, 0.4)`,
                    fontWeight: 600,
                    fontSize: {
                      xs: `14px`,
                      sm: `20px`,
                      md: `22px`,
                      lg: `24px`,
                      xl: `26px`,
                    },
                    lineHeight: `29.05px`,
                    '&:hover': { color: `rgba(40, 32, 61, 1)` },
                  }}
                >
                  {data?.title}
                </Typography>
              </button>
            ))}
          </Breadcrumbs>
        </Box>
        <Box sx={{ display: `flex`, gap: `10px`, alignItems: `center` }}>
          <Typography
            sx={{
              fontSize: `14px`,
              fontWeight: 500,
              lineHeight: `16.94px`,
              color: `rgba(40, 32, 61, 1)`,
            }}
          >
            {loggedInEmployeeName}
            <Typography
              sx={{
                textAlign: `end`,
                fontSize: `12px`,
                lineHeight: `14.52px`,
                color: `rgba(40, 32, 61, 0.4)`,
              }}
            >
              {loggedInEmployeeSystemRole}
            </Typography>
          </Typography>
          <Image
            onClick={(e) => setAnchorEl(e.currentTarget)}
            width={36}
            height={36}
            src={profileImageSrc}
            sx={{
              borderRadius: `50px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onError={() => setProfileImageSrc(userAvatar)}
          />
        </Box>
      </Box>
    </>
  )
}

export default TopBar
